import { ProtectedPage } from 'components/Page';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { useGetAllowList } from 'hooks/useGetAllowList';
import styles from 'styles/Allowlist.module.css';
import { AllowlistItem } from 'components/AllowlistItem';
import { useDebouncedCallback } from 'use-debounce';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'components/Button';
import toast from 'react-hot-toast';
import { useGetQuoteExtractionInboxes } from 'hooks/useGetQuoteExtractionInboxes';
import { useGetTmsOrdersInboxes } from 'hooks/useGetTmsOrdersInboxes';

/**
 * @function Allowlist
 * @description
 * Allows users to whitelist certain addresses in order
 * for LISA to filter out unwanted messages.
 */
const AllowList = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get('search') || '';
    const sort = searchParams.get('sort');
    const [field, order] = sort ? sort.split(':') : [];

    const { allowList, isValidating, error, mutate, key } = useGetAllowList();
    const { has_quote_extraction_inbox } = useGetQuoteExtractionInboxes();
    const { has_tms_orders_inbox } = useGetTmsOrdersInboxes();

    // Debounce callback
    const debounced = useDebouncedCallback(
        // function
        (value) => {
            setSearchParams({ search: value });
        },
        // delay in ms
        300
    );

    /**
     * @function sortAllowList
     * @description
     * Update query params with sort order. Should
     * be in the format of 'field:order' where field
     * is the field to sort by and order is either 'asc'
     * or 'desc'.
     */
    const sortAllowList = (field, order) => {
        setSearchParams({ sort: `${field}:${order}` });
    };

    /**
     * @function clearFilters
     * @description
     * Clear all query params.
     */
    const clearFilters = () => {
        setSearchParams({});
        mutate(key);
    };

    return (
        <ProtectedPage
            title='Allowlist'
            wide
            restrictions={['allowlist', 'login-required']}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                }}
            >
                <div className={styles.card} style={{ gap: 0 }}>
                    <h2>Add a new recipient</h2>
                    <AllowlistItem />
                </div>

                <div className={styles.card}>
                    <h2>Current allowlist</h2>
                    <div className={styles.header}>
                        <div className={styles.searchbar}>
                            <FaMagnifyingGlass className={styles.icon} />
                            <input
                                type='text'
                                defaultValue={search}
                                onChange={(e) => debounced(e.target.value)}
                                className={styles.search}
                                placeholder='Search by name or address...'
                            />
                        </div>
                        <div className={styles.sort}>
                            {(field || search) && (
                                <Button
                                    onClick={clearFilters}
                                    size='sm'
                                    type='button'
                                    uitype='ghost'
                                    color='red'
                                >
                                    Clear filters
                                </Button>
                            )}
                            <Button
                                onClick={() => {
                                    if (field === 'source_name') {
                                        sortAllowList(
                                            'source_name',
                                            order === 'asc' ? 'desc' : 'asc'
                                        );
                                    } else {
                                        sortAllowList('source_name', 'asc');
                                    }
                                }}
                                size='sm'
                                type='button'
                                uitype='ghost'
                                color={
                                    field === 'source_name'
                                        ? 'primary'
                                        : 'secondary-dark'
                                }
                            >
                                {field === 'source_name'
                                    ? order === 'asc'
                                        ? 'Sort by name (asc)'
                                        : 'Sort by name (desc)'
                                    : 'Sort by name'}
                            </Button>
                            <Button
                                onClick={() => {
                                    if (field === 'source_recipient') {
                                        sortAllowList(
                                            'source_recipient',
                                            order === 'asc' ? 'desc' : 'asc'
                                        );
                                    } else {
                                        sortAllowList(
                                            'source_recipient',
                                            'asc'
                                        );
                                    }
                                }}
                                size='sm'
                                type='button'
                                uitype='ghost'
                                color={
                                    field === 'source_recipient'
                                        ? 'primary'
                                        : 'secondary-dark'
                                }
                            >
                                {field === 'source_recipient'
                                    ? order === 'asc'
                                        ? 'Sort by address (asc)'
                                        : 'Sort by address (desc)'
                                    : 'Sort by address'}
                            </Button>
                            <Button
                                onClick={() => {
                                    if (field === 'created_at') {
                                        sortAllowList(
                                            'created_at',
                                            order === 'asc' ? 'desc' : 'asc'
                                        );
                                    } else {
                                        sortAllowList('created_at', 'asc');
                                    }
                                }}
                                size='sm'
                                type='button'
                                uitype='ghost'
                                color={
                                    field === 'created_at'
                                        ? 'primary'
                                        : 'secondary-dark'
                                }
                            >
                                {field === 'created_at'
                                    ? order === 'asc'
                                        ? 'Sort by created (asc)'
                                        : 'Sort by created (desc)'
                                    : 'Sort by created'}
                            </Button>
                            <Button
                                onClick={() => {
                                    if (field === 'updated_at') {
                                        sortAllowList(
                                            'updated_at',
                                            order === 'asc' ? 'desc' : 'asc'
                                        );
                                    } else {
                                        sortAllowList('updated_at', 'asc');
                                    }
                                }}
                                size='sm'
                                type='button'
                                uitype='ghost'
                                color={
                                    field === 'updated_at'
                                        ? 'primary'
                                        : 'secondary-dark'
                                }
                            >
                                {field === 'updated_at'
                                    ? order === 'asc'
                                        ? 'Sort by updated (asc)'
                                        : 'Sort by updated (desc)'
                                    : 'Sort by updated'}
                            </Button>
                        </div>
                    </div>

                    {isValidating && <p>Loading...</p>}
                    {!isValidating && allowList && allowList.length === 0 && (
                        <p>No addresses in the allowlist.</p>
                    )}
                    {error && (
                        <p className={styles.error}>
                            ⚠ There was an error loading the allowlist.
                            {
                                // We don't know the shape of the error object
                                // so we just print it out.
                                ' ' + error.toString()
                            }
                            <Button
                                onClick={() => {
                                    toast.promise(mutate(), {
                                        loading: 'Refreshing...',
                                        success: 'Refreshed allowlist',
                                        error: 'Error refreshing allowlist',
                                    });
                                }}
                                size='sm'
                                type='button'
                                uitype='ghost'
                            >
                                Retry
                            </Button>
                        </p>
                    )}

                    <div className={styles.allowListItems}>
                        {allowList.length > 0 &&
                            allowList.map((item) => (
                                <AllowlistItem
                                    key={item.source_recipient}
                                    allowlistItem={item}
                                    showAutoQuote={has_quote_extraction_inbox}
                                    showTmsOrders={has_tms_orders_inbox}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </ProtectedPage>
    );
};

export default AllowList;
