import { Button } from 'components/Button';
import {
    AutocompleteInput,
    HorizontalSelect,
    Input,
    MultiEmailInput,
} from 'components/Form';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { Tooltip } from 'react-tooltip';

import { RateProviderItem } from './RateProviderItem';
import { CUSTOM_RATES_DISTANCES, CustomRateGrid } from './CustomRateGrid';
import { CustomRateContractedLanes } from './CustomRateContractedLanes';
import { VEHICLE_CLASSES } from './consts';

import settingsStyles from 'styles/Settings.module.css';
import styles from '../styles.module.css';
import { CheckboxInput } from 'components/Form/Input/CheckboxInput';

function flattenObject(obj, parentKey = '', result = {}) {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const newKey = parentKey ? `${parentKey}.${key}` : key;
            const value = obj[key];

            if (
                typeof value === 'object' &&
                !Array.isArray(value) &&
                value !== null
            ) {
                flattenObject(value, newKey, result);
            } else {
                result[newKey] = value;
            }
        }
    }
    return result;
}

const providers = [
    {
        name: 'avrl',
        display_name: 'AVRL',
        extraFields: (form_methods) => {
            return (
                <Input
                    type='text'
                    placeholder='AVRL Shipper Code'
                    label='rate_providers.avrl.shipper_code'
                    register={form_methods.register}
                    watch={form_methods.watch}
                    errors={form_methods.errors}
                    size='sm'
                    narrow
                    required
                    hideErrorMessage
                />
            );
        },
        inline: true,
    },
    { name: 'greenscreens', display_name: 'Greenscreens' },
    { name: 'truckstop', display_name: 'Truckstop' },
    {
        name: 'custom',
        display_name: 'Custom',
        extraFields: (_form_methods) => (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                }}
            >
                <CustomRateGrid name_prefix='rate_providers.custom.base_rates' />
                <CustomRateContractedLanes name_prefix='rate_providers.custom.contracted_lanes' />
            </div>
        ),
        inline: false,
    },
];

const AutoQuoteSettingsCard = ({ allowlistItem, onSubmit }) => {
    const defaultValues = useMemo(() => {
        const {
            copy_emails,
            reply_all,
            default_pickup_location,
            default_vehicle_class,
            default_pickup_date,
            default_delivery_date,
            rate_providers,
        } = allowlistItem?.auto_quote_settings ?? {};

        const rate_providers_settings = rate_providers?.reduce(
            (acc, provider) => {
                const { rate_provider, ...settings } = provider;
                acc[`rate_providers.${rate_provider}.selected`] = true;
                const flattened_settings = flattenObject(settings);
                Object.entries(flattened_settings).forEach(
                    ([setting_name, setting_value]) => {
                        if (setting_name.includes('base_rates')) {
                            acc[
                                `rate_providers.${rate_provider}.${setting_name}`
                            ] = setting_value.reduce(
                                (vehicle_rates, base_rate) => {
                                    vehicle_rates[base_rate.id] =
                                        base_rate.rate;
                                    return vehicle_rates;
                                },
                                []
                            );
                        } else {
                            acc[
                                `rate_providers.${rate_provider}.${setting_name}`
                            ] = setting_value;
                        }
                    }
                );
                return acc;
            },
            {}
        );

        return {
            copy_emails,
            reply_all,
            default_pickup_location,
            default_vehicle_class: default_vehicle_class
                ? default_vehicle_class
                : 'Tractor Trailer',
            default_pickup_date: default_pickup_date
                ? default_pickup_date
                : { type: 'disabled' },
            default_delivery_date: default_delivery_date
                ? default_delivery_date
                : { type: 'disabled' },
            ...(rate_providers_settings &&
            Object.keys(rate_providers_settings).length
                ? { ...rate_providers_settings }
                : {}),
        };
    }, [allowlistItem]);

    const form_methods = useForm();

    const {
        control,
        register,
        watch,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = form_methods;

    useEffect(() => {
        Object.entries(defaultValues).forEach(([key, value]) => {
            setValue(key, value);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues]);

    const initial_order = useMemo(() => {
        let next_not_found_idx =
            allowlistItem?.auto_quote_settings?.rate_providers?.length ?? 0;

        return providers.reduce((acc, { name }) => {
            const provider_index =
                allowlistItem?.auto_quote_settings?.rate_providers?.findIndex(
                    ({ rate_provider }) => rate_provider === name
                );

            const index =
                provider_index > -1 ? provider_index : next_not_found_idx++;
            acc[name] = index;
            return acc;
        }, {});
    }, [allowlistItem?.auto_quote_settings?.rate_providers]);

    const [providersOrder, setProvidersOrder] = useState(initial_order);

    const submitHandler = useCallback(
        (values) => {
            const selected_providers = Object.entries(
                values?.rate_providers ?? {}
            )
                .filter(([_, settings]) => settings.selected)
                .map(([provider, settings]) => {
                    delete settings.selected;

                    if (provider === 'custom') {
                        const base_rates = VEHICLE_CLASSES.reduce(
                            (acc_vehicle, { value: vehicle_class }) => {
                                const vehicle_rates =
                                    CUSTOM_RATES_DISTANCES.reduce(
                                        (acc_distance, distance) => {
                                            if (
                                                settings.base_rates[
                                                    vehicle_class
                                                ]?.[distance.id]
                                            ) {
                                                acc_distance.push({
                                                    ...distance,
                                                    rate: Number(
                                                        settings.base_rates[
                                                            vehicle_class
                                                        ][distance.id]
                                                    ),
                                                });
                                            }
                                            return acc_distance;
                                        },
                                        []
                                    );

                                if (vehicle_rates.length) {
                                    acc_vehicle[vehicle_class] = vehicle_rates;
                                }
                                return acc_vehicle;
                            },
                            {}
                        );

                        const contracted_lanes =
                            settings?.contracted_lanes.filter(
                                (lane) =>
                                    lane.origin && lane.destination && lane.rate
                            );

                        settings.base_rates = base_rates;
                        settings.contracted_lanes = contracted_lanes;
                    }

                    settings.margin = Number(settings.margin);

                    return {
                        rate_provider: provider,
                        ...settings,
                    };
                })
                .sort(
                    (a, b) =>
                        providersOrder[a.rate_provider] -
                        providersOrder[b.rate_provider]
                );
            onSubmit({
                ...values,
                rate_providers: selected_providers.length
                    ? selected_providers
                    : undefined,
            });
        },
        [onSubmit, providersOrder]
    );

    const changeProviderOrder = useCallback(
        (index, direction) => {
            setProvidersOrder((prev) => {
                const a = Object.entries(prev).find(
                    ([_, order]) => order === index
                )[0];
                const b = Object.entries(prev).find(
                    ([_, order]) => order === index + direction
                )[0];

                const tmp = { ...prev };

                tmp[a] = index + direction;
                tmp[b] = index;

                return tmp;
            });
        },
        [setProvidersOrder]
    );

    return (
        <div>
            <h2 className={settingsStyles.heading}>Auto Quote Setting</h2>
            <FormProvider {...form_methods}>
                <form
                    className={settingsStyles.form}
                    onSubmit={handleSubmit(submitHandler)}
                >
                    <h3 className={styles.sectionTitle}>Reply settings</h3>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                        }}
                    >
                        <span
                            style={{
                                color: 'var(--secondary-darkest)',
                                fontSize: 'var(--text-xs)',
                                fontWeight: 600,
                            }}
                        >
                            Emails to send a CC
                        </span>
                        <div style={{ maxWidth: '80%' }}>
                            <MultiEmailInput
                                emails={watch('copy_emails')}
                                onChange={(emails) =>
                                    setValue('copy_emails', emails)
                                }
                            />
                        </div>
                    </div>

                    <CheckboxInput
                        name='reply_all'
                        value='reply_all'
                        label={`Reply to all recipients`}
                        checked={watch('reply_all')}
                        onChange={() =>
                            setValue('reply_all', !getValues('reply_all'))
                        }
                        style={{ padding: 0 }}
                    />

                    <hr style={{ width: '80%', marginLeft: 0 }} />

                    <h3
                        className={styles.sectionTitle}
                        style={{ marginBottom: '8px' }}
                    >
                        Default values settings
                    </h3>
                    <div
                        style={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                        }}
                    >
                        <AutocompleteInput
                            id='default_pickup_location'
                            value={watch('default_pickup_location') || ''}
                            setValue={setValue}
                            register={register}
                            errors={errors}
                            watch={watch}
                            placeholder='Default Pickup Location'
                            size='sm'
                            hideErrorMessage
                            hideEndIcon
                        />

                        <HiOutlineInformationCircle
                            size={24}
                            data-tooltip-content='A fallback when the customer does not specify a location in the email'
                            data-tooltip-id='default-pickup-info-tooltip'
                        />

                        <Tooltip id='default-pickup-info-tooltip' />
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                        }}
                    >
                        <span
                            style={{
                                color: 'var(--secondary-darkest)',
                                fontSize: 'var(--text-xs)',
                                fontWeight: 600,
                            }}
                        >
                            Default Vehicle
                        </span>
                        <div
                            style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                            }}
                        >
                            <HorizontalSelect
                                id='default_vehicle_class'
                                control={control}
                                errors={errors}
                                options={VEHICLE_CLASSES}
                                className={styles.defaultVehicleClassSelect}
                            />

                            <HiOutlineInformationCircle
                                size={24}
                                data-tooltip-content='A fallback when the customer does not specify a vehicle type in the email'
                                data-tooltip-id='default-vehicle-info-tooltip'
                            />

                            <Tooltip id='default-vehicle-info-tooltip' />
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                        }}
                    >
                        <div
                            style={{
                                color: 'var(--secondary-darkest)',
                                fontSize: 'var(--text-xs)',
                                fontWeight: 600,
                            }}
                        >
                            Default Pickup date
                        </div>

                        <div style={{ display: 'flex', gap: '12px' }}>
                            <div className={styles.radioItem}>
                                <input
                                    id='defaultPickupDateDisabled'
                                    type='radio'
                                    value='disabled'
                                    {...register('default_pickup_date.type')}
                                />
                                <label htmlFor='defaultPickupDateDisabled'>
                                    Disabled
                                </label>
                            </div>

                            <div className={styles.radioItem}>
                                <input
                                    id='defaultPickupDateFixed'
                                    type='radio'
                                    value='fixed'
                                    {...register('default_pickup_date.type')}
                                />
                                <label htmlFor='defaultPickupDateFixed'>
                                    Fixed
                                </label>

                                <HiOutlineInformationCircle
                                    size={16}
                                    data-tooltip-content='Consider a pickup date based on when the email was received'
                                    data-tooltip-id='default-pickup-date-fixed-tooltip'
                                />

                                <Tooltip
                                    id='default-pickup-date-fixed-tooltip'
                                    style={{ maxWidth: '80%' }}
                                />
                            </div>

                            <div className={styles.radioItem}>
                                <input
                                    id='defaultPickupDateMileage'
                                    type='radio'
                                    value='mileage'
                                    {...register('default_pickup_date.type')}
                                />
                                <label htmlFor='defaultPickupDateMileage'>
                                    Based on the delivery date
                                </label>

                                <HiOutlineInformationCircle
                                    size={16}
                                    data-tooltip-content='If a lane specifies a delivery date but does not indicate a pickup date, the pickup date will be inferred based on the distance the vehicle can travel on the first day and on subsequent days'
                                    data-tooltip-id='default-pickup-date-mileage-tooltip'
                                />

                                <Tooltip
                                    id='default-pickup-date-mileage-tooltip'
                                    style={{ maxWidth: '80%' }}
                                />
                            </div>
                        </div>

                        {watch('default_pickup_date.type') === 'fixed' && (
                            <HorizontalSelect
                                id='default_pickup_date.value.days_from_today'
                                control={control}
                                errors={errors}
                                options={[
                                    { value: 0, label: 'Today' },
                                    { value: 1, label: 'Tomorrow' },
                                ]}
                                className={styles.defaultVehicleClassSelect}
                            />
                        )}

                        {watch('default_pickup_date.type') === 'mileage' && (
                            <div style={{ display: 'flex', gap: '8px' }}>
                                <Input
                                    type='text'
                                    placeholder='First Day Miles'
                                    label={`default_pickup_date.value.first_day_miles`}
                                    register={register}
                                    watch={watch}
                                    errors={errors}
                                    hideErrorMessage
                                    size='sm'
                                    narrow
                                    validate={(value) => !isNaN(Number(value))}
                                />

                                <Input
                                    type='text'
                                    placeholder='Additional Days Miles'
                                    label={`default_pickup_date.value.additional_days_miles`}
                                    register={register}
                                    watch={watch}
                                    errors={errors}
                                    hideErrorMessage
                                    size='sm'
                                    narrow
                                    validate={(value) => !isNaN(Number(value))}
                                />
                            </div>
                        )}
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                        }}
                    >
                        <div
                            style={{
                                color: 'var(--secondary-darkest)',
                                fontSize: 'var(--text-xs)',
                                fontWeight: 600,
                            }}
                        >
                            Default Delivery Date
                        </div>

                        <div style={{ display: 'flex', gap: '12px' }}>
                            <div className={styles.radioItem}>
                                <input
                                    id='defaultDeliveryDateDisabled'
                                    type='radio'
                                    value='disabled'
                                    {...register('default_delivery_date.type')}
                                />
                                <label htmlFor='defaultDeliveryDateDisabled'>
                                    Disabled
                                </label>
                            </div>

                            <div className={styles.radioItem}>
                                <input
                                    id='defaultDeliveryDateMileage'
                                    type='radio'
                                    value='mileage'
                                    {...register('default_delivery_date.type')}
                                />
                                <label htmlFor='defaultDeliveryDateMileage'>
                                    Based on the pickup date
                                </label>

                                <HiOutlineInformationCircle
                                    size={16}
                                    data-tooltip-content='If a lane specifies a pickup date but does not indicate a delivery date, the delivery date will be inferred based on the distance the vehicle can travel on the first day and on subsequent days'
                                    data-tooltip-id='default-delivery-date-mileage-tooltip'
                                />

                                <Tooltip
                                    id='default-delivery-date-mileage-tooltip'
                                    style={{ maxWidth: '80%' }}
                                />
                            </div>
                        </div>

                        {watch('default_delivery_date.type') === 'mileage' && (
                            <div style={{ display: 'flex', gap: '8px' }}>
                                <Input
                                    type='text'
                                    placeholder='First Day Miles'
                                    label={`default_delivery_date.value.first_day_miles`}
                                    register={register}
                                    watch={watch}
                                    errors={errors}
                                    hideErrorMessage
                                    size='sm'
                                    narrow
                                    validate={(value) => !isNaN(Number(value))}
                                />

                                <Input
                                    type='text'
                                    placeholder='Additional Days Miles'
                                    label={`default_delivery_date.value.additional_days_miles`}
                                    register={register}
                                    watch={watch}
                                    errors={errors}
                                    hideErrorMessage
                                    size='sm'
                                    narrow
                                    validate={(value) => !isNaN(Number(value))}
                                />
                            </div>
                        )}
                    </div>

                    <hr style={{ width: '80%', marginLeft: 0 }} />

                    <h3 className={styles.sectionTitle}>Rate settings</h3>

                    {providers
                        .sort(
                            (a, b) =>
                                providersOrder[a.name] - providersOrder[b.name]
                        )
                        .map(({ name, extraFields, inline }, index) => (
                            <div
                                key={name}
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {index > 0 && (
                                        <FaArrowUp
                                            color='var(--everest-green)'
                                            onClick={() =>
                                                changeProviderOrder(index, -1)
                                            }
                                            style={{
                                                cursor: 'pointer',
                                                marginTop:
                                                    index ===
                                                    providers.length - 1
                                                        ? '0.25rem'
                                                        : 0,
                                            }}
                                        />
                                    )}
                                    {index < providers.length - 1 && (
                                        <FaArrowDown
                                            color='var(--everest-red)'
                                            onClick={() =>
                                                changeProviderOrder(index, 1)
                                            }
                                            style={{
                                                cursor: 'pointer',
                                                marginTop:
                                                    index === 0 ? '0.25rem' : 0,
                                            }}
                                        />
                                    )}
                                </div>

                                <div
                                    style={{
                                        padding: '0.25rem 0 0.25rem 0.5rem',
                                    }}
                                >
                                    {index + 1}.
                                </div>

                                <RateProviderItem
                                    name={name}
                                    checked={watch(
                                        `rate_providers.${name}.selected`
                                    )}
                                    onChange={() =>
                                        setValue(
                                            `rate_providers.${name}.selected`,
                                            !getValues(
                                                `rate_providers.${name}.selected`
                                            )
                                        )
                                    }
                                    extraFields={extraFields}
                                    inline={inline}
                                />
                            </div>
                        ))}

                    <Button
                        uitype='cta'
                        color='primary-dark'
                        size='lg'
                        fullwidth
                    >
                        Save Rates
                    </Button>
                </form>
            </FormProvider>
        </div>
    );
};

export { AutoQuoteSettingsCard };
