import { Button } from 'components/Button';
import { Input } from 'components/Form';
import { useForm } from 'react-hook-form';

import styles from 'styles/Settings.module.css';

const TmsOrdersSettingsCard = ({ allowlistItem, onSubmit }) => {
    const {
        register,
        watch,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: allowlistItem.tms_orders_settings ?? {},
    });

    return (
        <div>
            <h2 className={styles.heading}>Customer Information</h2>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <Input
                    type='text'
                    label='customer_code'
                    placeholder='Customer Code'
                    name='customer_code'
                    register={register}
                    errors={errors}
                    watch={watch}
                    size='lg'
                />

                <Input
                    type='text'
                    label='revenue_code'
                    placeholder='Revenue Code'
                    name='revenue_code'
                    register={register}
                    errors={errors}
                    watch={watch}
                    size='lg'
                />

                <Button uitype='cta' color='primary-dark' size='lg' fullwidth>
                    Save
                </Button>
            </form>
        </div>
    );
};

export { TmsOrdersSettingsCard };
