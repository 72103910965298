import { useMemo } from 'react';

import toast from 'react-hot-toast';
import { WithContext as ReactTags, SEPARATORS } from 'react-tag-input';

import classNames from 'classnames';

import styles from 'styles/Form.module.css';

export const MultiEmailInput = ({ emails = [], onChange }) => {
    const tags = useMemo(() => {
        return emails.map((email) => ({
            id: email,
            text: email,
            className: '',
        }));
    }, [emails]);

    const handleDelete = (index) => {
        onChange?.(emails.filter((_, i) => i !== index));
    };

    const handleAddition = (tag) => {
        if (/^[\w.+-]+@[\w.-]+\.\w{2,}$/.test(tag.text)) {
            onChange?.([...emails, tag.text]);
        } else {
            toast.error(`Invalid email address: ${tag.text}`);
        }
    };

    const handleInputBlur = (value) => {
        if (value) {
            handleAddition({ id: value, text: value, className: '' });
        }
    };

    return (
        <ReactTags
            tags={tags}
            separators={[
                SEPARATORS.ENTER,
                SEPARATORS.COMMA,
                SEPARATORS.SPACE,
                SEPARATORS.TAB,
            ]}
            handleAddition={handleAddition}
            handleDelete={handleDelete}
            handleInputBlur={handleInputBlur}
            classNames={{
                tagInput: styles.multiEmailInputTagInput,
                tagInputField: styles.multiEmailInputTagInputField,
                selected: styles.multiEmailInputSelected,
                tag: styles.multiEmailInputTag,
                remove: classNames(
                    styles.multiEmailInputRemove,
                    'ReactTags__remove' // Bug in the library that uses the original classname
                ),
            }}
            placeholder=''
        />
    );
};
